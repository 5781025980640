import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppDataService } from 'src/app/core/app-data/app-data.service';
import { SERIAL_NUMBER } from 'src/app/shared/api.constants';

@Injectable({
  providedIn: 'root',
})
export class SerialNumberService {
  constructor(private appDataService: AppDataService) {}

  public getEquipmentBySerialNumber(serialNo: string): Observable<any> {
    const queryParams = {
      provider: 'ESPP',
      serial_number: serialNo,
      indicator: 'V',
    };
    return this.appDataService.get<any>(SERIAL_NUMBER, queryParams);
  }
}
