import { trigger, transition, style, animate, state } from '@angular/animations';
import { Component, ContentChildren, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, combineLatest, fromEvent, merge, Observable, of, pipe, Subscription, throwError } from 'rxjs';
import { map, filter, debounceTime, distinctUntilChanged, exhaustMap, tap, pairwise, startWith, switchMap, catchError } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/modules/authentication/authentication.service';
import { Coverage } from 'src/app/core/types/equipment';
import { Quote } from 'src/app/core/types/quote';
import { AppInformationService } from 'src/app/shared/services/app-information/app-information.service';
import { CustomerService } from 'src/app/shared/services/customer/customer.service';
import { QuoteFormService } from 'src/app/shared/services/quote-form/quote-form.service';
import { QuoteService, EquipmentCoverageData, QuoteRequestData } from 'src/app/shared/services/quote.service';
import { CoverageTermService } from './services/coverage-term/coverage-term.service';
import { EquipModelService } from './services/equip-model/equip-model.service';
import { EquipmentTypeService } from './services/equipment-type/equipment-type.service';
import { ManufacturerService, Term } from './services/manufacturer/manufacturer.service';
import { Pin17Service, SerialNumber } from './services/pin17/pin17.service';
import { QuoteTypeService } from './services/quote-type/quote-type.service';
import { SaleTypeService } from './services/sale-type/sale-type.service';
import { UseTypeService } from './services/use-type/use-type.service';
import { createBaseValidator, createEquipmentStatusValidator, createMfrWarrrantyValidator, createShortBaseValidator, EsppFormValidator, createBaseHoursValidator } from './validators/espp-form-validator';
import { VolvoModelValidator } from './validators/volvo-model-validator';
import { Utils } from 'src/app/core/utils/helpers';
import { HeaderStore } from 'src/app/core/storage/stores/header-store';
import { EsppQuoteType } from 'src/app/core/enum/EsppQuoteType';
import { even, RxwebValidators } from '@rxweb/reactive-form-validators';
import { QuoteContext, QuoteForm } from './quote-methods/QuoteContext';
import { NewQuote } from './quote-methods/NewQuote';
import { ExtendedQuote } from './quote-methods/EteQuote';
import { UsedQuote } from './quote-methods/UsedQuote';
import { EquipmentStatus, EquipmentStatusService } from './services/equipment-status/equipment-status.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSelectChange } from '@angular/material/select';
import { DealerService } from './services/dealer/dealer.service';
import { Dealership, Salesperson } from './services/dealer/dealer.service.types';
import { Permission } from 'src/app/core/auth/permissions.types';
import { MockedDataService } from 'src/app/core/services/mocked-data/mocked-data.service';
import { AuthorizationService } from 'src/app/core/auth/authorization.service';
import { SerialNumberService } from './services/serial_number/serial-number.service';

type DynamicClass = { [col: string]: boolean }
const VOLVO_NEW = 'NEW';
const VOLVO_ETE = 'EXTEND THE EXTENDED';
const VOLVO_USED = 'USED';
const VOLVO_REMANUFACTURED = 'REMAN COMPONENT';
const VOLVO_REFURBISHED = 'REFURBISHED';

// interface ValidationResult { 
//     [key:string]:boolean;
//    }


//  const requiredIf(condition: (control: AbstractControl) => boolean): (control: FormControl) => ValidationResult {
//     return (control: FormControl): ValidationResult => {
//       // Important: control.parent might not be defined, depending on your form structure
//       return !condition(control.parent) || !!control.value ? null : { [`required_if`]: true };
//     };
//   }

const formatQarrantyTerm = (term: number | string, hours: number | string, termOffset: number, coverage: string) => {
    let offsetTerm = parseInt('' + term)
    let formattedHours = (hours !== '9999') ? hours : 'Unlimited';
    return (offsetTerm >= 0 ? offsetTerm : term) + ' / ' + ' ' + formattedHours + ' ' + coverage
}

@Component({
    selector: 'espp-quoter-test',
    templateUrl: './quoter.component.html',
    styleUrls: ['./quoter.component.scss'],
    animations: [
        trigger(
            'fade',
            [
                transition(
                    ':enter',
                    [
                        // style({ opacity: 0 }),
                        animate(1000, style({ opacity: 1, height: '*' }))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        animate(1000, style({ opacity: 0, height: 0 }))
                    ]
                )
            ]
        ),
    ]
})
export class EsppQuoterComponent extends HeaderStore implements OnInit, OnDestroy {

    public eteMultipileCoverages: boolean = false;
    //public existingCoverages: string[] = [];
    private quoteErroSubscription = new Subscription();
    public planTermMonths: number[] = []
    public planTermHours: number[] = []
    public planTermObject = {}

    loading = false;
    public spin(): void {
        this.loading = true;
    }

    public get existingCoverages(): string[] {
        const equipment: SerialNumber = this.equipment.value.filter(equipment => equipment.pin_17 === this.pin17)[0];
        if (!equipment) return [];
        return [...new Set(equipment.coverages.map(coverage => coverage.coverage))]
    }

    public get existingCoverageTypes() {
        return [...new Set(this.existingCoverages)];
    }

    public get existingCoverageTerms() {
        const currentCoverage = this.existingCoverage;
        if (currentCoverage && !this.selectedEte) {
            const equipment: SerialNumber = this.equipment.value.filter(equipment => equipment.pin_17 === this.pin17)[0];
            return (equipment.coverages).filter(coverage => coverage.coverage == currentCoverage).map(coverage => `${coverage.term} / ${coverage.hours}`)
        }
        return [];
    }

    public updateQuoteMessages: string[] = [];

    public existingCoverageTexts: string = '';
    public coveragePeriodTexts: string = '';
    public failedVerifiSerialNumber: boolean = false;

    quoteForm: FormGroup = new FormGroup({
        mfr_program: new FormControl(this.mfrProgram, [Validators.required]),
        test_quote: new FormControl(true),
        customer_name: new FormControl({ value: null, disabled: false }, [Validators.minLength(3), Validators.maxLength(50)]),
        customer_id: new FormControl(null),
        quote_type: new FormControl({ value: null, disabled: false }, [EsppFormValidator.required(this.quoteTypeEnum!, this.mfrProgram, 'QUOTE_TYPE')]),
        sale_type: new FormControl({ value: null, disabled: false }, [Validators.required]),
        use_type: new FormControl({ value: null, disabled: false }, [Validators.required]),
        primary_application: new FormControl({ value: null, disabled: true }),
        serial_number: new FormControl({ value: null, disabled: true }, [Validators.minLength(8)]),
        pin17: new FormControl({ value: null, disabled: true }, [RxwebValidators.required({ conditionalExpression: x => x.quote_type === EsppQuoteType.ETE }), EsppFormValidator.minLengthNullable(17), Validators.maxLength(17)], [VolvoModelValidator.model(this.pin17Service)]),
        manufacturer: new FormControl({ value: null, disabled: true }, [Validators.required]),
        equipment_type: new FormControl({ value: null, disabled: true }, [Validators.required]),
        model: new FormControl({ value: null, disabled: true }, [Validators.required]),
        model_year: new FormControl({ value: null, disabled: true }, [Validators.required]),
        ship_date: new FormControl({ value: null, disabled: true }),
        delivery_date: new FormControl({ value: null, disabled: true }),
        warranty_term: new FormControl({ value: null, disabled: true }, [RxwebValidators.required({ conditionalExpression: (x, y) => x.quote_type == VOLVO_NEW })]),
        warranty_expiration: new FormControl({ value: null, disabled: true }),
        current_hours: new FormControl({ value: null, disabled: true }, [Validators.required, Validators.pattern(/^-?\d*[.,]?\d{0,2}$/)]),
        term: new FormControl({ value: null, disabled: true }, [Validators.required]),
        planTerm_Months: new FormControl({ value: null, disabled: true }, [Validators.required]),
        planTerm_Hours: new FormControl({ value: null, disabled: true }, [Validators.required]),
        retail_value: new FormControl({ value: null, disabled: true }, []),
        existing_coverage: new FormControl({ value: null, disabled: true }, []),
        existing_term: new FormControl(null),
        existing_expiration: new FormControl(null),
        coverages: new FormArray([]),
        equipment_status: new FormControl(null, [RxwebValidators.required({ conditionalExpression: x => x.quote_type === EsppQuoteType.USED })]),
        coverage_period: new FormControl({ value: null, disabled: true }, []),
        coverage_type: new FormControl({ value: null, disabled: true }, []),
        service_fee_or_deductible: new FormControl({ value: null, disabled: true }, []),






        industry_segment: new FormControl(null),
        industry_sub_segment: new FormControl(null),
        component_type: new FormControl(null),
        component_model: new FormControl(null),
        part_number: new FormControl(null),
        uchp_ship_date: new FormControl(null),
        uchp_warranty_date: new FormControl(null),
        markup: new FormControl(null),
    },
        [
            EsppFormValidator.canQuote(this.useTypeService),
            createShortBaseValidator(),
            createBaseHoursValidator(),
            createMfrWarrrantyValidator(),
            createBaseValidator(),
            createEquipmentStatusValidator(this)
        ],
        //[VolvoModelValidator.validateModel(this.pin17Service,this.quoteTypeEnum)],
    );

    getFormValidationErrors() {
        if (!this.quoteForm) return;
        Object.keys(this.quoteForm.controls).forEach(key => {
            const controlErrors = this.quoteForm.get(key)?.errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach(keyError => {
                    // console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                });
            }
        });
    }

    //form control getters
    get quoteTypeControl(): AbstractControl {
        if (!this.quoteForm) return new FormControl();
        return this.quoteForm.controls.quote_type
    }

    get customerNameControl(): AbstractControl {
        return this.quoteForm.controls.customer_name
    }

    get testAutocompleteControl(): AbstractControl {
        return this.quoteForm.controls.test_autocomplete
    }

    get saleTypeControl(): AbstractControl {
        return this.quoteForm.controls.sale_type
    }

    get useTypeControl(): AbstractControl {
        return this.primmaryApplicationCOntrol
    }
    /**
     * get dealership control
     */
    get dealershipControl(): AbstractControl {
        return this.quoteForm.controls.dealership
    }
    /**
     * get salesperson control
     */
    get salespersonControl(): AbstractControl {
        return this.quoteForm.controls.salesperson
    }

    get pin17Control(): AbstractControl {
        return this.quoteForm.controls.pin17
    }

    get serialNumberControl(): AbstractControl {
        return this.quoteForm.controls.serial_number
    }

    get deliveryDateControl(): AbstractControl {
        return this.quoteForm.controls.delivery_date
    }

    get warrantyTermControl(): AbstractControl {
        if (!this.quoteForm) return new FormControl();
        return this.quoteForm.controls.warranty_term
    }

    get warrantyHoursControl(): AbstractControl {
        return this.quoteForm.controls.warranty_hours
    }

    get warrantyExpirationControl(): AbstractControl {
        return this.quoteForm.controls.warranty_expiration
    }

    get equipmentStatusControl(): AbstractControl {
        return this.quoteForm.controls.equipment_status
    }

    get industrySegmentControl(): AbstractControl {
        return this.quoteForm.controls.industry_segment
    }

    get industrySubSegmentControl(): AbstractControl {
        return this.quoteForm.controls.industry_sub_segment
    }

    get primmaryApplicationCOntrol(): AbstractControl {
        return this.quoteForm.controls.use_type
    }

    get manufacturerControl(): AbstractControl {
        return this.quoteForm.controls.manufacturer
    }

    get equipmentTypeControl(): AbstractControl {
        return this.quoteForm.controls.equipment_type
    }

    get modelControl(): AbstractControl {
        return this.quoteForm.controls.model
    }

    get modelYearControl(): AbstractControl {
        return this.quoteForm.controls.model_year
    }

    get componentTypeControl(): AbstractControl {
        return this.quoteForm.controls.component_type
    }

    get componentModelControl(): AbstractControl {
        return this.quoteForm.controls.component_model
    }

    get partNumberControl(): AbstractControl {
        return this.quoteForm.controls.part_number
    }

    get existingCoverageControl(): AbstractControl {
        return this.quoteForm.controls.existing_coverage 
    }

    get existingTermControl(): AbstractControl {
        return this.quoteForm.controls.existing_term
    }

    get existingExpirationControl(): AbstractControl {
        return this.quoteForm.controls.existing_expiration
    }

    get shipDateControl(): AbstractControl {
        return this.quoteForm.controls.ship_date
    }

    get warrantyDateControl(): AbstractControl {
        return this.quoteForm.controls.uchp_warranty_date
    }

    get currentHoursControl(): AbstractControl {
        if (!this.quoteForm) return new FormControl();
        return this.quoteForm.controls.current_hours
    }

    get termControl(): AbstractControl {
        return this.quoteForm.controls.term
    }
    get termMonthsControl(): AbstractControl {
        return this.quoteForm.controls.planTerm_Months
    }
    get termHoursControl(): AbstractControl {
        return this.quoteForm.controls.planTerm_Hours
    }

    get retailValueControl(): AbstractControl {
        return this.quoteForm.controls.retail_value
    }

    get markupControl(): AbstractControl {
        return this.quoteForm.controls.markup
    }

    get coveragePeriodControl(): AbstractControl {
        return this.quoteForm.controls.coverage_period
    }

    get coverageTypeControl(): AbstractControl {
        return this.quoteForm.controls.coverage_type
    }

    get serviceFeeOrDeductibleControl(): AbstractControl {
        return this.quoteForm.controls.service_fee_or_deductible
    }

    //form value getters
    get quoteTypeEnum() {
        let keys = Object.keys(EsppQuoteType).filter((x) => EsppQuoteType[x] == this.quoteType);
        return keys.length > 0 ? keys[0] as EsppQuoteType : EsppQuoteType.NONE;
    }

    get quoteType(): string {
        return this.quoteTypeControl.value
    }

    get customerName(): string {
        return this.customerNameControl.value
    }

    get testAutocomplete(): any {
        return this.testAutocompleteControl.value
    }

    get customerID(): number {
        return this.quoteForm.controls.customer_id.value
    }

    get saleType(): string {
        return this.saleTypeControl.value
    }

    get equipmentStatus(): any | null {
        return this.equipmentStatusControl.value
    }

    get industrySegment(): string | null {
        return this.industrySegmentControl.value
    }

    get industrySubSegment(): string | null {
        return this.industrySubSegmentControl.value
    }

    get primaryApplication(): string {
        return this.primmaryApplicationCOntrol.value
    }

    get manufacturer(): string | null {
        if (!this.manufacturerControl.value) {
            return null;
        }
        return (this.manufacturerControl.value)?.split(',')[0]
    }

    get ratingTypeKey(): string | null {
        if (!this.manufacturerControl.value) {
            return null;
        }
        return this.selectedEte ? this.ratingType : (this.manufacturerControl.value).split(',')[1]
    }

    get equipmentType(): string {
        return this.equipmentTypeControl.value
    }

    get model(): string {
        return this.modelControl.value
    }

    get serialNumber(): string {
        return this.serialNumberControl.value
    }

    get pin17(): string {
        return this.pin17Control.value
    }

    get modelYear(): number {
        return this.modelYearControl.value
    }

    get componentType(): string {
        return this.componentTypeControl.value
    }

    get componentModel(): string {
        return this.componentModelControl.value
    }

    get partNumber(): string | null {
        return this.partNumberControl.value
    }

    get existingCoverage(): string {
        return this.existingCoverageControl.value
    }

    get existingTerm() {
        return this.existingTermControl.value
    }

    get existingExpiration(): string {
        return this.existingExpirationControl.value
    }

    get shipDate(): string {
        return this.shipDateControl.value
    }

    get deliveryDate(): string {
        return this.deliveryDateControl.value
    }

    get warrantyDate(): string {
        return this.warrantyDateControl.value
    }

    get currentHours(): number {
        return this.currentHoursControl.value
    }

    get term() {
        return this.termControl.value
    }

    get warrantyTerm(): string | null {
        if (this.warrantyTermControl) {
            return this.warrantyTermControl.value
        }
        return null
    }

    get baseHours(): number {
        var hours = 99999999;
        const baseWarranty = this.warrantyTerm
        if (!baseWarranty) {
            return hours;
        }
        if (baseWarranty.match(/[oOtThHeErR]/g)) {
            return hours
        }

        if (baseWarranty.includes('Unlimited')) return hours;

        const baseHours = baseWarranty.match(/\d+/g)

        return baseHours ? parseInt(baseHours[1]) : hours

    }

    get maxHours(): number {
        return this.getMaxHours()
    }

    private getMaxHours(): number {
        if (this.quoteType === VOLVO_USED) {
            // return 1000000;
            const equipType = this.models.find(type => this.model == type.model)
            if (!equipType) return 1000000;
            if (parseInt(equipType.max_hours) == 0) return 1000000;

            return parseInt(equipType.max_hours)
        }
        return this.baseHours
    }

    get retailValue(): number {
        return this.retailValueControl.value
    }

    get markup(): number {
        return this.markupControl.value
    }

    get deliverDate() {
        return this.deliveryDateControl.value
    }


    //show getters based on quotetype
    get showIfNew() {
        if (this.selectedEte) {
            return false
        }
        return this.quoteType === VOLVO_NEW
    }

    get showIfUsed() {
        return this.quoteType === VOLVO_USED
    }

    get showIfEte() {
        //Since ETE‘ value also is “NEW”, use <selectedEte> instead of this method
        return this.quoteType === VOLVO_ETE
    }

    get showIfReman() {
        return this.quoteType === VOLVO_REMANUFACTURED
    }

    get showIfRefurbished() {
        return this.quoteType === VOLVO_REFURBISHED
    }

    //MISC form getters
    get mfrProgram(): string {
        return this.userService.currentMfrProgram?.name || '';
    }

    get existingHours(): number {
        const existingTerm: number[] = (this.existingTerm ?? '').split("/").map(item => parseInt(item.trim()));
        return existingTerm[1]
    }



    //form disabled states
    get step1(): boolean {
        return this.saleTypeControl.value;
    }

    public customers: BehaviorSubject<{ [key: string]: string }[]> = new BehaviorSubject<{ [key: string]: string }[]>([]);
    public manufacturers: { [key: string]: string }[] = [{ name: 'Volvo' }];
    public quoteTypes: { [key: string]: string }[] = [];
    public salesTypes: { [key: string]: string }[] = [];
    public primaryApplications: { [key: string]: string | boolean }[] = [];
    public equipment: BehaviorSubject<SerialNumber[]> = new BehaviorSubject<SerialNumber[]>([]);
    public equipmentTypes: { [key: string]: string }[] = [];
    public models: { [key: string]: string }[] = [];
    public modelYears: number[] = [];
    // public coverages: string[] = [];
    public coverages: any[] = [];
    public warrantyTerms: Term[] = [];
    public equipmentStatuses: EquipmentStatus[] = [];
    public equipmentSet: boolean = false;
    //public daysOutsideOfBase: number = 0;
    public messageObservable = new Subscription();
    private ratingType: string | null = null;

    private isPresale: boolean = false;
    public selectedEte: boolean = false;


    public dataOfExtendTheExtend:any

    selectedValue(event: any) {
        const selectedData = {
            value: event.value,
            text: event.source.triggerValue
        };
        // console.log('the quote type:',selectedData);
        

        // this.isPresale = selectedData.text.toLowerCase().includes('presale')

        // if (this.isPresale) {
        //     this.deliveryDateControl.addValidators([Validators.required]);
        //     this.warrantyExpirationControl.addValidators([Validators.required]);
        //     this.deliveryDateControl.updateValueAndValidity({ emitEvent: false });
        //     this.warrantyExpirationControl.updateValueAndValidity({ emitEvent: false });
        // }else{
        //     this.deliveryDateControl.removeValidators([Validators.required])
        //     this.warrantyExpirationControl.removeValidators([Validators.required]);
        //     this.deliveryDateControl.updateValueAndValidity({ emitEvent: false });
        //     this.warrantyExpirationControl.updateValueAndValidity({ emitEvent: false });
        // }

        // this.quoteForm.reset({
        //     quote_type: selectedData.value
        // }, { emitEvent: false });
        // this.quoteForm.clearValidators();
        // this.quoteForm.updateValueAndValidity();

        if(selectedData.text == VOLVO_ETE){
            this.manufacturerControl.disable();
            this.equipmentTypeControl.disable();
            this.modelControl.disable();
            // this.existingCoverageControl.disable();
            this.coveragePeriodControl.disable();
            this.coverageTypeControl.disable();
            this.serviceFeeOrDeductibleControl.disable();
            //const VOLVO_ETE = 'EXTEND THE EXTENDED';
            this.selectedEte = true;
        }else{
            this.selectedEte = false;
            // this.quoteForm.reset({
            //     quote_type: selectedData.value
            // }, { emitEvent: false });
            // this.quoteForm.clearValidators();
            // this.quoteForm.updateValueAndValidity();
            
        }
    }

    constructor(
        private dealerService: DealerService,
        private appInformation: AppInformationService,
        private quoteService: QuoteService,
        private userService: AuthenticationService,
        private authorizationService: AuthorizationService,
        private customerService: CustomerService,
        private quoteTypeService: QuoteTypeService,
        private manufacturerService: ManufacturerService,
        private saleTypeService: SaleTypeService,
        private useTypeService: UseTypeService,
        private pin17Service: Pin17Service,
        private equipmentTypeService: EquipmentTypeService,
        private modelService: EquipModelService,
        private coverageService: CoverageTermService,
        private quoteFormService: QuoteFormService,
        private equipmentStatusService: EquipmentStatusService,
        private router: Router,
        private mockedDataService: MockedDataService,
        @Inject(MAT_DIALOG_DATA) public quote: Quote,
        private elRef: ElementRef,
        private serialNumberService: SerialNumberService,
    ) { super() }
    disableSubmit = true;
    ngOnDestroy() {
        this.quoteFormService.modalMessage.next([]);
        this.messageObservable.unsubscribe();
        this.quoteErroSubscription.unsubscribe();
        this.mockedDataService.resetMockedData();
    }
    /**
     * Check permissions to display Dealership and salesperson fields
     */
    public get canDealerSelect() {
        return this.authorizationService.hasPermission([Permission.DEALERSHIP_SELECTION]);
    }
    /**
     * Dealership list
     */
    dealers: Dealership[] = [];
    /**
     * This function is used to get dealers when user types in dealership search
     * @param filter
     * @memberof
     * */
    public getDealers(filter: string | number) {
        filter && this.dealerService
            .getDealers(filter)
            .subscribe(
                (result) => {
                    this.dealers = result
                },
                (errorResponse: HttpErrorResponse) => {
                    console.log('error', errorResponse)
                }
            );
    }
    private mainKeys(item: {cnh_dealership_number?: number}): string[] {
        const keys = ['company', item.cnh_dealership_number ? 'cnh_dealership_number' : 'id']
        return keys
    }
    /**
     * Settings for Dealership dropdown
     */
    dealershipOptions = {
        labelText: 'Dealership',
        optionKey: {
            getMainKeys: (item: any) => this.mainKeys(item),
            sub: ['address', 'city', 'state', 'zip'],
        },
        valueKey: 'id',
    }
    /**
     * Salespeople list
     */
    salespeople: any[] = [];
    /**
     * This function is used to get salespeople when user selects a dealership
     * @param filter
     * */
    public getSalespeople(filter: string | number, dealerId?: string | number) {
        (dealerId || this.dealershipControl.value) && this.dealerService
            .getSalesperson(dealerId || this.dealershipControl.value, filter)
            .subscribe(
                (result) => {
                    if (result.length === 0) this.updateQuoteMessages.push('No salespeople were found for dealership selected') 
                    this.salespeople = result;
                },
                (errorResponse: HttpErrorResponse) => {
                    console.log('error', errorResponse);
                }
            );
    }
    /**
     * Settings for salesperson input
     */
    salespersonOptions = {
        labelText: 'Salesperson',
        optionKey: {
            main: ['first_name', 'last_name'],
        },
        valueKey: 'id',
        removeSeparator: true
    }

    public errorMessages: string[] = [];
    private planCoverages: Array<{ term: number, hours: number }> = [];
    private get existingPlanOfETE() {
        return this.dataOfExtendTheExtend.plan_info.coverages.filter(coverage => !coverage.coverage.includes('TTM'))[0]
    }
    ngOnInit(): void {
        if (this.canDealerSelect) {
            this.quoteForm.addControl('dealership', new FormControl(null));
            this.quoteForm.addControl('salesperson', new FormControl(null, []))
        }
        /**
         * Populate salesperson dropdown using dealership selected
         */
        this.dealershipControl?.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe(dealer => {
                this.getSalespeople('');
                this.getQuoteTypes().subscribe(types => { });
                this.mockedDataService.addMockedData('dealership_id', dealer);
                //this.salespersonControl.reset();
                this.salespeople = [];
                this.quoteTypeControl.reset()
                this.quoteTypes = [];
                this.saleTypeControl.reset()
                this.salesTypes = [];
                this.primmaryApplicationCOntrol.reset()
                this.manufacturerControl.reset()
                this.manufacturers = [];
                this.equipmentTypeControl.reset()
                this.equipmentTypes = [];
                this.modelControl.reset()
                this.models = [];
                this.modelYearControl.reset()
                this.shipDateControl.reset()
                this.deliveryDateControl.reset()
                this.warrantyExpirationControl.reset()
                this.currentHoursControl.reset()
                this.retailValueControl.reset()
                this.resetPlanTerm();
                this.planCoverages = [];
                this.planTermMonths = [];
                this.planTermHours = [];
            });
        this.salespersonControl?.valueChanges
            .pipe(distinctUntilChanged())
            .subscribe(user => {
                this.mockedDataService.addMockedData('user_id', user);
                // this.getQuoteTypes().subscribe(types => { });
            })
        this.quoteErroSubscription = this.quoteService.errorMessage.subscribe(messages => {
            this.errorMessages = messages;
        })
        this.messageObservable = this.quoteFormService.modalMessage.subscribe(
            message => this.updateQuoteMessages = message
        )
        this.useTypeControl.valueChanges
            .subscribe(type => {
                // console.log(type)
            })
        
        this.getEquipmentYear();

        this.getQuoteTypes().subscribe(types => { });

        const quoteTypeObservable = this.quoteTypeControl.valueChanges.pipe(
            filter(type => type !== null),
            map(type => {
                switch (type[1]) {
                    case EsppQuoteType.ETE:
                        return EsppQuoteType.NEW;
                    default:
                        return type
                }

            })
        )

        this.useTypeService.getUseTypes(this.mfrProgram).subscribe((useTypes) => {
            this.primaryApplications = useTypes
        })

        //reset all fields once update quote type
        quoteTypeObservable
            .pipe(
                startWith(null),
                pairwise(),
                tap(
                    ([previousValue, currentValue]) => {
                        if (previousValue !== currentValue) {
                            this.saleTypeControl.reset()
                            this.primmaryApplicationCOntrol.reset()
                            this.manufacturerControl.reset()
                            this.equipmentTypeControl.reset()
                            this.modelControl.reset()
                            this.modelYearControl.reset()
                            this.shipDateControl.reset()
                            this.deliveryDateControl.reset()
                            this.warrantyExpirationControl.reset()
                            //this.warrantyTermControl.reset()
                            this.currentHoursControl.reset()
                            //this.termControl.reset()
                            this.retailValueControl.reset()

                            this.resetPlanTerm();
                        }
                    }
                ),
                switchMap(value => this.getSalesType(value[1])))
            .subscribe(types => {
                this.updateQuoteMessages = []
                this.saleTypeControl.enable();
                const type = types.find(type => type.id == this.saleType)
                if (type) {
                    this.saleTypeText = +type.quote_type_id
                }

                if (this.quote) {
                    const equipment = this.quote.equipment[0].coverages.find(current => current.coverage_type == 'EW');
                    if (equipment && equipment.sale_type) {
                        this.saleTypeText = equipment.sale_type.id
                    }
                }

            })

        // this.primmaryApplicationCOntrol.valueChanges
        //     .pipe(
        //         startWith(null),
        //         pairwise(),
        //         tap(
        //             ([previousValue, currentValue]) => {
        //                 if (previousValue !== currentValue) {
        //                     // this.saleTypeControl.reset()
        //                     // this.primmaryApplicationCOntrol.reset()
        //                     this.manufacturerControl.reset()
        //                     this.equipmentTypeControl.reset()
        //                     this.modelControl.reset()
        //                     this.modelYearControl.reset()
        //                     this.shipDateControl.reset()
        //                     this.deliveryDateControl.reset()
        //                     this.warrantyExpirationControl.reset()
        //                     this.warrantyTermControl.reset()
        //                     this.currentHoursControl.reset()
        //                     this.termControl.reset()
        //                     this.retailValueControl.reset()
        //                 }
        //             }
        //         ),
        //     )

        //     .subscribe(([prev, next]: [any, any]) => {
        //     })

        this.saleTypeControl.valueChanges
            .pipe(
                pairwise(),
                tap(
                    ([previousValue, currentValue]) => {
                        if ( (previousValue !== currentValue) && !this.selectedEte ) {
                            this.primmaryApplicationCOntrol.reset()
                            this.manufacturerControl.reset()
                            this.equipmentTypeControl.reset()
                            this.modelControl.reset()
                            this.modelYearControl.reset()
                            this.shipDateControl.reset()
                            this.deliveryDateControl.reset()
                            this.warrantyExpirationControl.reset()
                            //this.warrantyTermControl.reset()
                            // this.currentHoursControl.reset()
                            //this.termControl.reset()
                            // this.retailValueControl.reset()
                            this.resetPlanTerm();
                        }
                    }
                ),
            )
            .subscribe(d => { })

        //update mfr downstream
        this.manufacturerControl.valueChanges
            .pipe(
                pairwise(),
                tap(
                    ([previousValue, currentValue]) => {
                        if (previousValue !== currentValue) {
                            this.equipmentTypeControl.reset()
                            this.modelControl.reset()
                            this.modelYearControl.reset()
                            this.shipDateControl.reset()
                            this.deliveryDateControl.reset()
                            this.warrantyExpirationControl.reset()
                            // this.warrantyTermControl.reset()
                            // this.currentHoursControl.reset()
                            //this.termControl.reset()
                            //this.retailValueControl.reset()
                            this.resetPlanTerm();
                        }
                    }
                ),
            )
            .subscribe(d => { })

        this.equipmentTypeControl.valueChanges
            .pipe(
                pairwise(),
                tap(
                    ([previousValue, currentValue]) => {
                        if (previousValue !== currentValue) {
                            this.modelControl.reset()
                            this.modelYearControl.reset()
                            this.shipDateControl.reset()
                            this.deliveryDateControl.reset()
                            this.warrantyExpirationControl.reset()
                            // this.warrantyTermControl.reset()
                            //this.currentHoursControl.reset()
                            // this.termControl.reset()
                            // this.retailValueControl.reset()
                            this.resetPlanTerm();
                        }
                    }
                ),
            )
            .subscribe(d => { })

        this.modelControl.valueChanges
            .pipe(
                pairwise(),
                tap(
                    ([previousValue, currentValue]) => {
                        if (previousValue !== currentValue) {
                            this.modelYearControl.reset()
                            this.shipDateControl.reset()
                            this.deliveryDateControl.reset()
                            this.warrantyExpirationControl.reset()
                            //this.warrantyTermControl.reset()
                            // this.currentHoursControl.reset()
                            // this.termControl.reset()
                            //  this.retailValueControl.reset()
                            this.resetPlanTerm();
                        }
                    }
                ),
            )
            .subscribe(d => { })

        this.modelYearControl.valueChanges
            .pipe(
                pairwise(),
                tap(
                    ([previousValue, currentValue]) => {
                        if (previousValue !== currentValue) {
                            this.shipDateControl.reset()
                            this.deliveryDateControl.reset()
                            this.warrantyExpirationControl.reset()
                            // this.warrantyTermControl.reset()
                            //this.currentHoursControl.reset()
                            //this.termControl.reset()
                            // this.retailValueControl.reset()
                            this.resetPlanTerm();
                        }
                    }
                ),
            )
            .subscribe(d => { })


        this.warrantyTermControl.valueChanges
            .pipe(
                pairwise(),
                tap(
                    ([previousValue, currentValue]) => {
                        if (previousValue !== currentValue) {
                            //this.currentHoursControl.reset()
                            // this.termControl.reset()
                            // this.retailValueControl.reset()
                            this.resetPlanTerm();
                        }
                    }
                ),
            )
            .subscribe(d => { })

        this.currentHoursControl.valueChanges
            .pipe(
                //filter(currentHours => this.quoteType == EsppQuoteType.USED),
                map(hour => parseInt(hour)))
            .subscribe(
                hours => {
                    if(this.selectedEte) this.termMonthsControl.enable();
                    if (hours > this.maxHours) {
                        this.currentHoursControl.setErrors({ 'maxNumber': true });
                    } else {
                        const errors = this.currentHoursControl.errors;
                        if (errors) delete errors['maxNumber'];
                        this.currentHoursControl.setErrors(errors);
                    }
                }
            )

        this.customerNameControl.valueChanges.pipe(
            // if character length greater then 4
            filter(res => res && res.length > 3)
            // Time in milliseconds between key events
            , debounceTime(1000)
            // If previous query is diffent from current   
            , distinctUntilChanged(),
            // switch map
            exhaustMap((term) => {
                return this.customerService.autocomplete(term);
            })
        )
            .subscribe((results) => {
                this.customers.next(results);
            });


        const saleTypeObservable = this.saleTypeControl.valueChanges.pipe(startWith(null));
        //this.saleTypeControl.valueChanges
        combineLatest([
            quoteTypeObservable,
            this.quoteForm.controls.sale_type.valueChanges
        ])
            .pipe(
                tap(d => {
                    // console.log(d)
                }),
                filter(d => d[0] && d[1]),
                filter(() => !this.selectedEte), //if ETE is selected, skip this
                //startWith([null,null]),
                map(latest => { return { quoteType: latest[0], saleType: latest[1].split(',')[0] } }),
                //filter(latest => latest.quoteType),
                exhaustMap(value => this.getManufacturers(value.quoteType, value.saleType, this.dealershipControl?.value))
            )
            .subscribe(mfrs => {
                this.primmaryApplicationCOntrol.enable();
                this.manufacturerControl.enable();
            })

        this.manufacturerControl.valueChanges
            .pipe(
                filter(mfr => mfr && mfr?.length > 0 && typeof(mfr) !== 'string'),
                exhaustMap(mfr => {
                    const manufacturer = mfr.split(',')[0];
                    const ratingType = mfr.split(',')[1];
                    this.ratingType = ratingType;
                    if (this.quoteType != 'USED') return of([]);
                    return this.equipmentStatusService.getEquipmentStatuses(manufacturer);
                })
            )
            .subscribe(
                status => this.equipmentStatuses = status
            )

        //observables to get the warranty terms
        combineLatest([
            quoteTypeObservable,
            this.saleTypeControl.valueChanges,
            this.manufacturerControl.valueChanges,
            this.modelControl.valueChanges,
            this.equipmentTypeControl.valueChanges
        ])
            .pipe(
                filter(d => d[2]),
                exhaustMap(([quoteType, saleType, manufacturer, model, equipmentType]) => {

                    const manufacturerKey = manufacturer?.split(',')[0] || null;
                    const ratingType = manufacturer?.split(',')[1] ||  null;

                    if (![quoteType, saleType, manufacturer, model, equipmentType].includes(null)) {
                        const saleTypeValue = saleType.split(',')[0];
                        return this.getWarrantyTerms(saleTypeValue, quoteType, manufacturerKey, model, equipmentType, ratingType)
                    }
                    return of([])
                })
            )
            .subscribe(() => { }, error => console.log("error ", error))

        this.primmaryApplicationCOntrol.valueChanges
            .pipe(startWith(null))
            .subscribe(application => {
                this.serialNumberControl.enable();
                if (!this.selectedEte) {
                    return
                }
                this.pin17Control.enable()
            })

        combineLatest([
            quoteTypeObservable,
            this.saleTypeControl.valueChanges,
            this.manufacturerControl.valueChanges,
        ])
            .pipe(
                startWith([null, null, null]),
                filter(latest => latest[2] != undefined && latest[1] != null),
                map(latest => {
                    const manufacturer = !this.selectedEte ? latest?.[2]?.split(',')?.[0] : null;
                    const ratingType = !this.selectedEte ? latest?.[2]?.split(',')?.[1] : null;
                    return { quoteType: latest[0], saleType: latest[1], manufacturer, ratingType }
                }),
                filter(latest => latest.quoteType && latest.saleType && latest.manufacturer && latest.ratingType),
                distinctUntilChanged((a, b) => a.manufacturer === b.manufacturer && a.saleType === b.saleType && a.quoteType === b.quoteType),
                exhaustMap(latest => {
                    return !this.selectedEte ? this.getEquipmentType(latest.quoteType, latest.saleType, latest.manufacturer, latest.ratingType) : of({})
                })
            )
            .subscribe(equipmentType => {
                !this.selectedEte && this.equipmentTypeControl.enable();
            });
            // For ETE quoting.
            combineLatest([
                quoteTypeObservable,
                this.saleTypeControl.valueChanges,
                this.manufacturerControl.valueChanges,
            ])
            .pipe(
                startWith([null, null, null]),
                filter(([quoteType, saleType, manufacturer]) => saleType != null && manufacturer !== undefined),
                distinctUntilChanged((a, b) => a !== b),
                exhaustMap(([quoteType, saleType, manufacturer]) => {
                    return this.getManufacturers(quoteType, saleType?.split(',')?.[0] || saleType, this?.dealershipControl?.value)
                }),
                map(manufacturers => manufacturers.map((mfr) => ({manufacturer: mfr.manufacturer.toLocaleLowerCase(), rating_type: mfr.rating_type})))
            ).subscribe(manufacturers => {
                const mfrValue = this.manufacturerControl.value
                const manufacturer = manufacturers.find(item => item.manufacturer === mfrValue.toLowerCase())
                if(manufacturer){
                    this.ratingType = manufacturer.rating_type
                    this.ratingTypeKey
                } else {
                    this.selectedEte && this.updateQuoteMessages.push('The manufacturer of the selected serial number is not available for quoting at this time. Please Please contact you program administrator for assistance.');
                }
            })

        combineLatest([
            quoteTypeObservable,
            this.saleTypeControl.valueChanges,
            this.manufacturerControl.valueChanges,
            this.equipmentTypeControl.valueChanges,
        ])
            .pipe(
                filter(d => d[2]),
                map(latest => {
                    const manufacturer = latest?.[2].split(',')[0];
                    const ratingType = latest?.[2].split(',')[1];
                    return { quoteType: latest?.[0], saleType: latest?.[1], manufacturer: manufacturer, equipmentType: latest?.[3], ratingType }
                }),
                filter(latest => latest.quoteType && latest.saleType && latest.manufacturer && latest.equipmentType),
                distinctUntilChanged((a, b) => a.manufacturer === b.manufacturer && a.saleType === b.saleType && a.quoteType === b.quoteType && a.equipmentType === b.equipmentType),
                exhaustMap(latest => {
                    return this.getEquipmentModels(latest.quoteType, latest.saleType, latest.manufacturer, latest.equipmentType, latest.ratingType);
                })
            )
            .subscribe(models => {
                if (this.modelControl.disabled && this.modelYearControl.disabled && this.termControl.disabled) {
                    this.modelControl.enable();
                    this.modelYearControl.enable();
                    this.termControl.enable();
                    this.termMonthsControl.enable()
                }
            }, (error: HttpErrorResponse) => {
                if (error.status === 500) {
                    const errMessage = 'This quote cannot be edited. Please contact the program administrator for assistance.';
                    this.updateQuoteMessages.push(errMessage);
                }
            });

        this.existingCoverageControl.valueChanges
            .subscribe(coverage => {
                if (this.existingCoverageTerms.length == 1) this.existingTermControl.setValue(this.existingCoverageTerms[0]);
            })


        combineLatest([
            quoteTypeObservable,
            this.saleTypeControl.valueChanges,
            this.manufacturerControl.valueChanges,
            this.modelControl.valueChanges,
            this.equipmentTypeControl.valueChanges,
            this.currentHoursControl.valueChanges,
            // this.warrantyTermControl.valueChanges,
        ])
            .pipe(
                filter(data => !data.includes(null)),
                debounceTime(1000),
                map(latest => {
                    const manufacturerKey = latest[2].split(',')[0];
                    const ratingType = latest[2].split(',')[1];
                    return { quoteType: latest[0], saleType: latest[1], manufacturer: manufacturerKey, model: latest[3], equipmentType: latest[4], hours: latest[5], ratingType }
                }),
                filter(latest => latest.hours != null && latest.hours >= 0),
                distinctUntilChanged((a, b) => a.manufacturer === b.manufacturer && a.saleType === b.saleType && a.quoteType === b.quoteType && a.equipmentType === b.model && a.equipmentType === b.model && a.hours === b.hours),
                switchMap(latest => this.getCoverageOptions(latest.quoteType, latest.saleType, latest.manufacturer, latest.equipmentType, latest.model, latest.hours, this.pin17, latest.ratingType || this.ratingType)),
            )
            .subscribe((l) => {
                //check l if it has both the selected term and hours if not reset quote form
                if (l.length > 0 && l.findIndex(planTerm => planTerm.term == (this.quoteForm.controls.planTerm_Months.value ?? Infinity)) == -1) {
                    this.quoteForm.controls.planTerm_Months.reset();
                }

                if (l.length > 0 && l.findIndex(planTerm => planTerm.hours == (this.quoteForm.controls.planTerm_Hours.value ?? Infinity)) == -1) {
                    this.quoteForm.controls.planTerm_Hours.reset();
                }

                //this.quoteForm.controls.planTerm_Months.reset();
                //this.quoteForm.controls.planTerm_Hours.reset();
                //this.esppCoverages = l
                //set plan options
                this.planTermMonths = this.quote?.plan_number || this.selectedEte ? 
                    [...new Set(
                        l.map(
                            (planCoverage) => {
                                return +planCoverage.term
                            }
                        )
                    )].filter(months => {
                        const realMonths = months - this.existingPlanOfETE.term
                        return realMonths >= 0
                    }).map(months => months - this.existingPlanOfETE.term)
                : [...new Set(
                    l.map(
                        (planCoverage) => {
                            return +planCoverage.term
                        }
                    )
                )];

                if (this.quote && this.quoteForm.controls.planTerm_Months.value) {
                    this.planTermHours = this.quote?.plan_number || this.selectedEte ? [
                        ...new Set(
                            this.coverages.filter(coverage => {
                                return coverage.term == this.quoteForm.controls.planTerm_Months.value;
                            })
                                .map(coverage => {
                                    return coverage.hours;
                                })
                        )
                    ].filter(hours =>  {
                        const realTermHours = hours - this.existingPlanOfETE.hours
                        return realTermHours >= this.existingPlanOfETE.hours
                    }).map(hours => hours - this.existingPlanOfETE.hours) 
                    : [
                        ...new Set(
                            this.coverages.filter(coverage => {
                                return coverage.term == this.quoteForm.controls.planTerm_Months.value;
                            })
                                .map(coverage => {
                                    return coverage.hours;
                                })
                        )
                    ]
                } 
        })
        this.currentHoursControl.valueChanges
            .subscribe(hours => {
                if (hours > this.existingHours) {
                    this.termControl.reset()
                    this.termMonthsControl.reset()
                    this.termHoursControl.reset()

                } else if (hours <= this.existingHours && this.esppCoverages.length == 1) {
                    this.termControl.setValue(this.esppCoverages[0])
                    this.termMonthsControl.setValue(this.esppCoverages[0].split(' / ')[0])
                    this.termHoursControl.setValue(this.esppCoverages[0].split(' / ')[1])
                }

            })
        //mod
        this.modelYearControl.valueChanges
            .subscribe(_ => {
                this.shipDateControl.enable();
                this.deliveryDateControl.enable();
            })

        combineLatest([
            //this.quoteTypeControl.valueChanges.pipe(startWith(null)),
            this.warrantyTermControl.valueChanges.pipe(startWith(null)),
            this.shipDateControl.valueChanges.pipe(startWith(null)),
            this.deliveryDateControl.valueChanges.pipe(startWith(null)),
        ])
            .pipe(
                map(latest => { return { warrantyTerm: latest[0], baseStart: latest[1] } }),
                filter(warranty => warranty.baseStart && warranty.warrantyTerm),
            )
            .subscribe(data => {
                // const baseStart: Date = typeof data.baseStart == 'string'? new Date(data.baseStart) : data.baseStart;
                // let term = (data.warrantyTerm).split("/").map(item => parseInt(item.replace(/\D/g, "").trim()))[0]
                // this.warrantyExpirationControl.setValue(new Date((new Date(baseStart.getTime())).setMonth(baseStart.getMonth() + term)))
            })

        this.deliveryDateControl.valueChanges
            .pipe(startWith(null))
            .subscribe(_ => {
                if (this.warrantyTermControl.disabled && this.warrantyExpirationControl.disabled) {
                    this.warrantyTermControl.enable();
                    this.warrantyExpirationControl.enable();
                }
            })


        /**
        Observable to detect when the form should add validation to the warranty expiration data control.
         */
        combineLatest(
            [
                this.warrantyTermControl.valueChanges.pipe(
                    startWith(null),
                    map(term => {
                        if (term == null) return null;
                        const termParts = term.split('/');
                        //Parses out the term from the term that is selected from the drop down
                        return {
                            term: (termParts[0]).trim(),
                            hours: termParts[1].includes['Unlimited'] ? Infinity : termParts[1]
                        };
                    })
                ),
                this.deliveryDateControl.valueChanges.pipe(startWith(null)),
            ]
        )
            .subscribe(
                data => {
                    if (EsppQuoteType.NEW !== this.quoteType) return;
                    //Once it is detected that the term data and the warranty start data is available to the user then the 
                    //validation will be added using the term data term field otherwise it will skip until these values are true
                    const termData = data[0];
                    const warrantyStartDate = new Date(data[1]);

                    if (termData && warrantyStartDate) {
                        //Adds the validation to the warranty expiration date control
                        const maxExpirationDate = new Date(warrantyStartDate.setMonth(warrantyStartDate.getMonth() + parseInt(termData.term)));
                        const day = maxExpirationDate.getDate();
                        const hours = maxExpirationDate.getHours();
                        //ger uear from maxExpirationdate
                        const hoursDiff = 6;

                        const minExpirationDate = new Date(warrantyStartDate.setHours(hours - hoursDiff));

                        this.warrantyExpirationControl.setValidators([RxwebValidators.maxDate({
                            value:
                                new Date(maxExpirationDate.setHours(maxExpirationDate.getHours() + hoursDiff))
                        }), RxwebValidators.minDate({ value: new Date(minExpirationDate) })]);

                        this.warrantyExpirationControl.updateValueAndValidity()
                    }
                }
            )

        this.warrantyExpirationControl.valueChanges
            .pipe(startWith(null))
            .subscribe(term => {
                this.currentHoursControl.enable();
                // this.existingCoverageControl.enable();
                this.retailValueControl.enable();
                this.markupControl.enable();
            })

        //ship date and delivery date observable
        combineLatest([
            this.shipDateControl.valueChanges.pipe(startWith(null)),
            this.deliveryDateControl.valueChanges.pipe(startWith(null)),
            this.warrantyExpirationControl.valueChanges.pipe(startWith(null)),
        ])
            .pipe(
                map(latest => { return { shipDate: latest[0], deliveryDate: latest[1], earrantyExpiration: latest[2] } }),
            )
            .subscribe((warrantyDates: { shipDate: Date, deliveryDate: Date, earrantyExpiration: Date }) => {
                if (warrantyDates.deliveryDate && warrantyDates.shipDate) {
                    if (this.warrantyTerms.length == 1) {
                        const warranty = this.warrantyTerms[0];
                        this.warrantyTermControl.patchValue(warranty.warranty_text, { emitEvent: false })
                        //const baseStart: Date = warrantyDates.shipDate
                        //let term = (this.warrantyTermControl.value).split("/").map(item => parseInt(item.replace(/\D/g, "").trim()))[0]
                        //this.warrantyExpirationControl.setValue(new Date((new Date(baseStart.getTime())).setMonth(baseStart.getMonth() + term)),{emitEvent: false})
                    }
                }
            })

        if (this.quote && this.quote.plan_number) {
            this.triggerSerialNoEndpointFunction(this.quote.equipment[0].serial_number, true)
        } else {
            this.setQuoteData(this.quote);
        }
        this.quoteForm.controls.planTerm_Months
            .valueChanges
            .subscribe(term => {
                //this.quoteForm.controls.planTerm_Hours.reset();
                this.quoteForm.controls.planTerm_Hours.enable();
                this.planTermHours = this.selectedEte ? [
                    ...new Set(
                        this.coverages.filter(coverage => {
                            return coverage.term == term;
                        })
                            .map(coverage => {
                                return coverage.hours;
                            })
                    )
                ].filter(hours =>  {
                    const realTermHours = hours - this.existingPlanOfETE.hours
                    return realTermHours >= this.existingPlanOfETE.hours
                }).map(hours => hours - this.existingPlanOfETE.hours)
                : [
                    ...new Set(
                        this.coverages.filter(coverage => {
                            return coverage.term == term;
                        })
                            .map(coverage => {
                                return coverage.hours;
                            })
                    )
                ]
                // * Patch for CEPS-166
                if(!term && this.quote) {
                    this.quoteForm.patchValue({
                        planTerm_Months: this.quote.plan_number ? this.ewCoverage.term - this.existingPlanOfETE.term: this.ewCoverage.term,
                    })
                }
            })
        this.quoteForm.controls.planTerm_Hours
            .valueChanges.subscribe(hours => {
                if(!hours && this.quote) {
                    this.quoteForm.patchValue({
                        planTerm_Hours: this.quote.plan_number ? this.ewCoverage.hours - this.existingPlanOfETE.hours : this.ewCoverage.hours
                    })
                }
            })

        this.serialNumberControl.valueChanges
            .pipe(debounceTime(2000))
            .subscribe(theData => {
                this.triggerSerialNoEndpointFunction(theData);
            });
        
        this.quoteForm.valueChanges
            .pipe(map((_) => this.quoteForm.getRawValue()))
            .subscribe(({quote_type, serial_number, sale_type, use_type, current_hours, planTerm_Months, planTerm_Hours}) => {
                const emptyFields = !quote_type || !serial_number || !sale_type || !use_type || !current_hours || !planTerm_Months || !planTerm_Hours
                if(!this.selectedEte) {
                    this.disableSubmit = this.quoteForm.invalid
                } else if(this.selectedEte && emptyFields) {
                  this.disableSubmit = true
                } else {
                  this.disableSubmit = false
                }
            })
    }

    private resetPlanTerm() {
        //check to see if the quote is in the updating state
        if (this.updatingQuote) return;
        this.quoteForm.controls.planTerm_Months.reset();
        this.quoteForm.controls.planTerm_Hours.reset();
    }

    private getWarrantyTerms(quoteType: string, program: string, manufacturer: string, model: string, equipType: string, ratingType: string) {
        return this.manufacturerService.getWarrantyTerms(quoteType, program, manufacturer, this.model, this.equipmentType, ratingType)
            .pipe(
                tap(coverages => {
                    if (coverages.length == 1) {
                        const warranty = coverages[0];
                        this.warrantyTermControl.setValue(warranty.warranty_text)
                    }

                    this.warrantyTerms = coverages;
                })
            )
    }
    // Ship date entry = 2/15/2021
    // Standard Warr Start = 3/5/2022
    // Standard Warr End = 3/5/2023
    // Warranty Term = 12/2500 Full
    private get daysOutsideOfBase() {
        if (!this.shipDate || !this.deliverDate) return 0;
        const deliveryDate: Date = new Date(this.deliverDate);
        const shipDate: Date = new Date(this.shipDate);
        const diffTime = Math.abs(deliveryDate.getTime() - shipDate.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays
    }

    public get baseWarrantyTerms() {
        const offset = this.termOffset;
        let coverages = this.warrantyTerms.map(warranty => {
            return warranty.warranty_text
        });
        return coverages;
    }

    private get isShortBase() {
        return this.daysOutsideOfBase > 365;
    }

    public get canNotShort(): boolean {
        if (this.manufacturer != 'VOLVO') return false;
        if (!this.warrantyTerm) return false;
        const baseTerm = (this.warrantyTerm).split("/").map(item => parseInt(item.replace(/\D/g, "").trim()))[0] ?? 0
        if (this.isShortBase && baseTerm >= 12) {
            return true;
        }
        return false;
    }

    private get termOffset() {
        const offset = Math.ceil(this.daysOutsideOfBase / 30) - 12;
        return offset > 0 ? offset : 0;
    }

    @ViewChild('saleType') saleTypeInput;
    //@ContentChildren(SomeTypeOrVarName, {descendants: true}) someField; 

    changeTimezone(date) {

        // suppose the date is 12:00 UTC
        var invdate = new Date(date.toLocaleString('en-US', {
            timeZone: 'America/Chicago'
        }));

        // then invdate will be 07:00 in Toronto
        // and the diff is 5 hours
        var diff = date.getTime() - invdate.getTime();

        // so 12:00 in Toronto is 17:00 UTC
        return new Date(date.getTime() - diff); // needs to substract

    }

    private get ewCoverage() {
        const primaryEquipment = this.quote.equipment[0];
        const coverages = primaryEquipment.coverages
        var ewCoverage: Coverage | null = coverages.filter(coverage => coverage.coverage_type == 'EW')[0]
        return ewCoverage
    }


    private updatingQuote = false;
    private setQuoteData(quote: Quote) {
        // console.log('quote', quote)
        if (!quote) return;
        this.updatingQuote = true;
        const dealerId = quote.dealership?.id || '';
        const salespersonId = quote.user?.id || '';
        if (this.canDealerSelect) {
            this.getDealers(dealerId);
            this.getSalespeople(salespersonId, dealerId);
        }
        const customerName = quote.customer?.name
        const primaryEquipment = quote.equipment[0];
        const coverages = primaryEquipment.coverages
        const baseCoverage: Coverage[] = coverages.filter(coverage => coverage.coverage_type == 'BASE')
        const isETEQuote = primaryEquipment.serial_number && quote.plan_number
        if (coverages.filter(coverage => coverage.coverage_type == 'EW').length < 1) {
            this.errorMessages.push('This quote cannot be edited. Please contact the program administrator for assistance.');
            return;
        }
        var ewCoverage: Coverage | null = coverages.filter(coverage => coverage.coverage_type == 'EW')[0]
        const extCoverage: Coverage | null = coverages.filter(coverage => coverage.coverage_type == 'EWW')[0]
        const term = ewCoverage?.term + ' / ' + ewCoverage?.hours

        const saleTypeValue = [ewCoverage.quote_type, ewCoverage.sale_type?.sale_type].join(',')
        const manufacturerKey = [primaryEquipment.manufacturer, quote.rating_type].join(',');
        const ratingType = quote.rating_type;
        if(isETEQuote) {
            this.selectedValue({
                source: { triggerValue: VOLVO_ETE },
                value: quote.id
            })
            this.triggerSerialNoEndpointFunction(primaryEquipment.serial_number)
        }
        // const test = this.salesTypes.find(type => type.sale_type == event.source.triggerValue);
        // this.saleTypeText = test ? +(test?.quote_type_id) : null;
        let warrantyTerm: string | null = null;
        if (this.warrantyTerms.length > -1 && !this.quote.equipment[0].mfr_warranty) {
            warrantyTerm = baseCoverage.reduce(
                (previousValue, currentValue, currentIndex, array) => {
                    const base = currentValue;
                    const hours = base.hours !== 9999 ? base.hours : 'Unlimited';
                    const lastIndex = currentIndex == array.length - 1
                    const endOfString = lastIndex ? '' : '& '
                    return previousValue + `${base.term} / ${hours} ${base.coverage} ${endOfString}`
                },
                ''
            ).trim();
        } else {
            warrantyTerm = this.quote.equipment[0].mfr_warranty;
        }
        //console.log( primaryEquipment.coverages.filter(coverage => coverage.coverage_type == 'EW').map(coverage => coverage.hours)[0])
        this.ratingType = ratingType;
        if (this.canDealerSelect) {
            this.quoteForm.patchValue({
                'dealership': dealerId,
                'salesperson': salespersonId
            })
        }
        this.quoteForm.patchValue({
            ...{
                quote_type: quote.quote_type,
                customer_name: customerName,
                // sale_type: ewCoverage.quote_type,
                sale_type: saleTypeValue,
                use_type: ewCoverage.use_type,
                serial_number: primaryEquipment.serial_number,
                pin17: primaryEquipment.pin_17,
                manufacturer: manufacturerKey,
                equipment_type: primaryEquipment.equipment_type,
                model: primaryEquipment.model,
                model_year: primaryEquipment.year,
                ship_date: primaryEquipment.manufacturer_ship_date,
                delivery_date: baseCoverage.length > 0 && baseCoverage[0].unknown_effective ? null : this.changeTimezone(new Date(primaryEquipment.manufacturer_date)),
                current_hours: primaryEquipment.hours,
                term: `${ewCoverage.term} / ${ewCoverage.hours}`,
                retail_value: primaryEquipment.value,
                equipment_status: primaryEquipment.status?.id,
                planTerm_Hours: this.quote.plan_number ? ewCoverage.hours - this.existingPlanOfETE?.hours : ewCoverage.hours,
                planTerm_Months: this.quote.plan_number ? ewCoverage.term - this.existingPlanOfETE?.term : ewCoverage.term,


                component_type: null,
                component_model: null,
                part_number: null,
                existing_coverage: null,
                existing_term: null,
                existing_expiration: null,
                uchp_ship_date: null,
                uchp_warranty_date: null,
            },
            ...(baseCoverage.length > 0 ? {
                // warranty_term: `${baseCoverage[0].term} / ${baseCoverage[0].hours} ${baseCoverage[0].coverage}`,
                warranty_term: warrantyTerm,
                warranty_expiration: baseCoverage[0].unknown_expiration ? null : new Date(baseCoverage[0]?.expiration_date),
            } : {})
        });
        this.updatingQuote = false;
        this.quoteForm.updateValueAndValidity()
        // console.log( 'this.planTermMonths',this.planTermMonths );
        // console.log( 'ewCoverage',ewCoverage );

        // this.planTermMonths = [...ewCoverage.term+'']
        // this.planTermHours = [...ewCoverage.hours+'']

        //this.termMonthsControl.setValue(ewCoverage.term + '')
        //this.termHoursControl.setValue(ewCoverage?.hours + '')
        //this.termHoursControl.enable()
    }

    private getQuoteTypes(): Observable<{ [key: string]: string }[]> {
        return this.quoteTypeService.getQuoteTypes()
            .pipe(
                map(quoteTypes => {
                    const quote = this.quote;
                    const serialNumber = quote?.equipment[0].serial_number
                    const planNumber = quote?.plan_number
                    if(serialNumber && planNumber) {
                        const ete = quoteTypes.findIndex(qt => qt.display_string === VOLVO_ETE)
                        const newIndex = quoteTypes.findIndex(qt => qt.display_string === 'NEW')
                        const nQuote = quoteTypes[newIndex]
                        const orderedQT = quoteTypes
                        orderedQT[newIndex] = quoteTypes[ete];
                        orderedQT[ete] = nQuote
                        return orderedQT
                    } else {
                        return quoteTypes
                    }
                }),
                tap(
                    (types) => {
                        if (types.length == 1) {
                            this.quoteTypeControl.setValue(types[0].id)
                        }
                        // const newTypeIndex = types.findIndex(types => types.id === 'NEW');
                        // if (newTypeIndex > -1) {
                        //     const presaleType = { ...types[newTypeIndex] };
                        //     presaleType.quote_type = presaleType.quote_type + ' - Presale'
                        //     types.splice(newTypeIndex + 1, 0, presaleType);
                        // }
                        this.quoteTypes = types;
                    }
                )
            )
    }

    private getManufacturers(quoteType: EsppQuoteType, saleType: string, dealershipId?: string): Observable<{ [key: string]: string }[]> {
        if (!this.quoteType) return of([]);
        return this.manufacturerService.getManufacturers(quoteType, saleType, dealershipId)
            .pipe(
                tap(
                    (manufacturers) => {
                        if (manufacturers.length == 1) {
                            this.manufacturerControl.setValue([manufacturers[0].manufacturer, this.ratingType].join(','))
                        }
                        this.manufacturers = manufacturers;
                    }
                )
            )
    }

    private getSalesType(quoteType: string): Observable<{ [key: string]: string }[]> {
        if (this.quoteType) {
            return this.saleTypeService.getSaleTypes(this.quoteType)
                .pipe(
                    tap(
                        (types) => {
                            if (types.length == 1) {
                                this.saleTypeControl.setValue(types[0].id)
                            }
                            this.salesTypes = types;
                        }
                    )
                )
        }
        return of([])
    }

    private getEquipmentType(program: string, saleType: string, manufacturer: string, ratingType: string): Observable<{ [key: string]: string }[]> {
        if (!program && !saleType && !manufacturer) return of([]);
        const saleTypeValue = saleType.split(',')[0];
        return this.equipmentTypeService.getEquipmentType(program, saleTypeValue, manufacturer, ratingType)
            .pipe(
                tap(types => {
                    if (types.length == 1) {
                        this.equipmentTypeControl.setValue(types[0].id)
                    }
                    this.equipmentTypes = types;
                })
            )
    }

    private getEquipmentModels(quoteType: string, saleType: string, manufacturer: string, equipType: string, ratingType: string): Observable<{ [key: string]: string }[]> {
        const saleTypeValue = saleType.split(',')[0];
        return this.modelService.getModels(quoteType, saleTypeValue, manufacturer, equipType, ratingType)
            .pipe(
                tap(models => {
                    if (models.length == 1) {
                        this.modelControl.setValue(models[0].id)
                    }
                    this.models = models;

                })
            )
    }

    public get esppCoverages() {
        let planCoverages = this.coverages.filter(coverage => !coverage.key)
        let comboCoverages = this.coverages.filter(coverage => coverage.key === 'COMBO')
        this.planTermObject = {}

        //filter out anything below base warranty
        if (this.warrantyTerm) {
            const term: number = (this.warrantyTerm).split("/").map(item => parseInt(item.replace(/\D/g, "").trim()))[0] ?? 0
            planCoverages = planCoverages.filter(planTerm => parseInt(planTerm.term) >= term);
        }

        const currentHours = this.currentHours;
        const planTerms = planCoverages.map((value) => {
            return (value.term - parseInt(value.term_plus)) + ' / ' + value.hours
        });


        [...planTerms].map((theValue) => {
            if (this.planTermObject[theValue.split(" / ")[0]]) {
                this.planTermObject[theValue.split(" / ")[0]].push(theValue.split(" / ")[1])
            } else {
                this.planTermObject[theValue.split(" / ")[0]] = [theValue.split(" / ")[1]]

            }
        })
        //this.planTermMonths = Object.keys(this.planTermObject)
        //this.planTermHours = this.planTermObject[this.termMonthsControl.value]




        return [
            ...planTerms,
        ];
    }

    private getCoverageOptions(quoteType: string, saleType: string, manufacturer: string, equipType: string, model: string, hours: number, serialNumber: string, ratingType: string): Observable<{ [key: string]: string }[]> {
        //parse out the term hours and description from the warranty term strin in the following format 12/2500 Full & 24/500 Engine
        const warrantyCoverage = this.quoteForm.controls.warranty_term.value;
        let warranties = [];
        if (quoteType == 'NEW' && warrantyCoverage) {
            warranties = warrantyCoverage
                .split('&')
                .map(coverage => {
                    const coverageParts = coverage.split('/');
                    const term = +coverageParts[0].trim();
                    const hours = coverageParts[1].split(' ')[1].trim() === 'Unlimited' ? 9999 : +coverageParts[1].split(' ')[1].trim();
                    const description = coverageParts[1].split(' ').splice(2).join(' ').trim();
                    return { term, hours, description };
                });
        }

        const saleTypeValue = saleType.split(',')[0];
        return this.coverageService.getCoverageOptions(quoteType, saleTypeValue, manufacturer, equipType, model, hours, serialNumber, ratingType, warranties)
            .pipe(
                tap(coverages => {
                    this.coverages = coverages
                    if (this.esppCoverages.length == 1) {
                        this.termControl.setValue(this.esppCoverages[0])
                        this.termMonthsControl.setValue(this.esppCoverages[0].split(' / ')[0])
                        this.termHoursControl.setValue(this.esppCoverages[0].split(' / ')[1])
                    }
                })
            )
    }

    private getEquipmentYear(): void {
        const currentYear = <number>(new Date().getFullYear());
        const maxYear = currentYear + 1;
        this.modelYears = ([...Array(10).keys()]).map(year => {
            return maxYear - year;
        })
    }

    public setCustomerID(name: string) {
        if (this.customers) {
            const customer = this.customers.value.filter(customer => customer.name === name)[0];
            this.customerNameControl.setValue(customer.name);
            this.quoteForm.controls.customer_id.setValue(customer.id)
        }
    }

    public setEquipment(pin17: string) {
        this.equipmentSet = true;
        const equipment: SerialNumber = this.equipment.value.filter(equipment => equipment.pin_17 === pin17)[0];
        this.manufacturerControl.setValue('VOLVO', { emitEvent: false });
        this.pin17Control.setValue(pin17, { emitEvent: false });
        this.serialNumberControl.setValue(equipment.serial_number, { emitEvent: false });
        const shipDate: Date = equipment.shipment_date;
        const deliveryDate: Date = equipment.deliver_date;
        this.shipDateControl.setValue(new Date(equipment.shipment_date), { emitEvent: false });
        this.deliveryDateControl.setValue(equipment.deliver_date, { emitEvent: false });
        this.modelControl.setValue(equipment.epg_model, { emitEvent: false });
        this.equipmentTypeControl.setValue(equipment.epg_model, { emitEvent: false });
        this.warrantyExpirationControl.setValue(new Date(equipment.warranty_expiration), { emitEvent: false });
        this.equipmentTypeControl.setValue(equipment.equipment_type, { emitEvent: false });
        this.modelYearControl.setValue(equipment.model_year, { emitEven: false })

        if (this.showIfEte) {
            let warranties = equipment.warranties;
            let baseWarranty = warranties[0];
            if (baseWarranty) {
                this.warrantyTermControl.setValue(`${equipment.warranty_term} / ${equipment.warranty_hours} ${baseWarranty.coverage}`, { emitEvent: false })
                this.warrantyTermControl.disable();
            }

            this.eteMultipileCoverages = (equipment.coverages).length > 1;
            this.manufacturerControl.setValue(equipment.manufacturer, { emitEvent: false })
            this.equipmentTypeControl.setValue(equipment.equipment_type, { emitEvent: false })
            this.modelYearControl.setValue(equipment.model_year, { emitEvent: false })
            let coverage = equipment.coverages[0]
            //@ts-ignore
            // this.existingCoverageControl.setValue(coverage.coverage, { emitEvent: false })
            //@ts-ignore
            this.existingTermControl.setValue(`${coverage.term} / ${coverage.hours}`, { emitEvent: false })
            //@ts-ignore
            this.existingExpirationControl.setValue(new Date(coverage.expiration_date), { emitEvent: false })

            if (this.modelYearControl.value) {
                this.modelYearControl.disable();
            }

            if (this.existingCoverageControl.value) {
                this.existingCoverageControl.disable();
            }

            if (this.existingTermControl.value) {
                this.existingTermControl.disable();
            }
        }

        if (this.modelYearControl.value) {
            this.modelYearControl.disable();
        }

        if (this.equipmentTypeControl.value) {
            this.equipmentTypeControl.disable();
        }

        if (this.manufacturerControl.value) {
            this.manufacturerControl.disable();
        }

        if (this.pin17Control.value) {
            this.pin17Control.disable();
        }

        if (this.serialNumberControl.value) {
            this.serialNumberControl.disable();
        }

        if (this.shipDateControl.value) {
            this.shipDateControl.disable();
        }

        if (this.deliveryDateControl.value) {
            this.deliveryDateControl.disable();
        }

        if (this.modelControl.value) {
            this.modelControl.disable();
        }


        if (this.warrantyExpirationControl.value) {
            this.warrantyExpirationControl.disable();
        }
    }
    private saleTypeText: number | null = null;
    saleTypeSelection(event) {
        //this.saleTypeText = event.source.triggerValue;
        const saleTypeValue = (event.source.triggerValue).split(',')[0];
        const test = this.salesTypes.find(type => type.sale_type == saleTypeValue);
        this.saleTypeText = test ? +(test?.quote_type_id) : null;
    }

    formatDate(date: Date | null): string | null {
        if (date == null) return null;
        const pad = (n) => n < 10 ? "0" + n : n;
        let d = date
        let dash = "-"
        let colon = ":"
        return d.getFullYear() + dash +
            pad(d.getMonth() + 1) + dash +
            pad(d.getDate()) + " " +
            pad(0) + colon +
            pad(0) + colon +
            pad(0)
    }

    customerNameOptions = {
        labelText: this.customerNameHeader,
        list: this.customers,
        optionValue: 'name',
        optionLabel: 'name',
        optionSelected: this.setCustomerID,
    }

    onSubmit() {
        if (!this.quoteForm.valid && !this.selectedEte) return;

        this.spin()
        const manufacturerKey = this.manufacturer ?? '';
        const ratingType = this.ratingTypeKey;
        const splitTerm = this?.term?.split('/')?.map(item => parseInt(item.trim()));
        const quoteForm: QuoteRequestData = {
            dealership_id: this.dealershipControl?.value,
            salesperson_id: this.salespersonControl?.value,
            volvo_ship_date: this.shipDate,
            customer_name: this.customerName == undefined || this.customerName == null ? null : this.customerName,
            quote_type: this.quoteType ?? '',
            sale_type: (this.saleType).split(',')[0],
            use_type: this.primaryApplication,
            manufacturer: manufacturerKey,
            equipment_type: this.equipmentType,
            model: this.model,
            equipment_status: this.equipmentStatus,
            model_year: this.modelYear,
            ...(this.quoteType == 'NEW' && !this.selectedEte ? {
                warranty_start_date: this.formatDate(this.deliverDate),
                warranty_end_date: this.formatDate(this.warrantyExpirationControl.value),
            } : {
                warranty_start_date: null,
                warranty_end_date: null
            }),
            ...(this.quoteType == 'NEW' && !this.selectedEte ? {
                warranty_term: (this.warrantyTerm ?? '')
                    .split('&')
                    .map(coverage => {
                        const baseWarranty = coverage.split("/").map(item => item.includes('Unlimited') ? 9999 : parseInt(item.replace(/\D/g, "").trim()));
                        const hours = baseWarranty[1]
                        // const baseCoverage = coverage.split('/')[1].replace(/[\d|\/]* /gmi, '').replace('Unlimited','')
                        const baseCoverage = coverage.split('/')[1].replace(/[0-9]/g, '').replace('Unlimited', '').trim()
                        return { term: (baseWarranty[0]), hours: (hours), coverage: baseCoverage };
                    }),
            } : { warranty_term: [] }),
            current_hours: this.currentHours,
            plan_term: {
                term: splitTerm?.[0],
                hours: splitTerm?.[1],
            },
            retail_value: parseFloat(this.retailValue + ''),
            rating_type: ratingType ?? '',
            is_presale: this.isPresale,
            plan_number: this.selectedEte ? this.dataOfExtendTheExtend?.plan_info?.plan_number : null,
            serial_number: this.selectedEte ? this.serialNumberControl?.value : null
        };
        if (this.quote) {
            this.quoteService.updateQuote(quoteForm, this.quote.id)
                .pipe(
                    catchError(errorResponse => {
                        return throwError(errorResponse);
                    })
                )
                .subscribe(
                    (quote) => {
                        this.quoteFormService.closeQuoteForm();
                        //this.quoteService.currentQuote.n
                    },
                    (errorResponse: HttpErrorResponse) => {
                        this.loading = false;
                        this.errorMessages.push(errorResponse.error.message);
                        Object.values(errorResponse.error.errors).map((errorMessage: any) => this.errorMessages.push(errorMessage))
                    }
                );
        } else {
            this.quoteService.storeQuote(quoteForm)
                .subscribe(
                    (quote) => {
                        this.quoteFormService.closeQuoteForm();
                        this.router.navigate(['apps', 'espp', 'quotes', quote.id])
                    },
                    (errorResponse: HttpErrorResponse) => {
                        this.loading = false;
                        this.errorMessages.push(errorResponse.error.message);
                        Object.values(errorResponse.error.errors).map((errorMessage: any) => this.errorMessages.push(errorMessage))
                    }
                );
        }
    }

    public afterPlanTermMonths() {
        this.planTermHours = this.selectedEte ? this.planTermObject[this.termMonthsControl.value + this.existingPlanOfETE.term].filter((hours) => {
            const currentPlanHours = this.existingPlanOfETE.hours
            return Number(hours) >= currentPlanHours
        }) : this.planTermObject[this.termMonthsControl.value]
        this.termHoursControl.enable()
        //this.termHoursControl.reset()


    }

    public afterPlanTermHours() {
        this.termControl.setValue(this.termMonthsControl.value + ' / ' + this.termHoursControl.value)
    }


    public triggerSerialNoEndpointFunction(serialNumber: string, update: boolean = false) {
        if (!serialNumber) {
            return
        }
        this.serialNumberService.getEquipmentBySerialNumber(serialNumber).subscribe(
            data => {
                this.dataOfExtendTheExtend = {};
                this.dataOfExtendTheExtend = data;

                const planInfo = this.dataOfExtendTheExtend?.plan_info;
                const coverages = planInfo?.coverages || [];
                const isPlanValid = coverages.some(coverage => coverage.effective_date && coverage.expiration_date);
                const isEquipmentNew = this.dataOfExtendTheExtend?.new === true;
                const isPromoCodeValid = planInfo?.promo_code !== 'ACP';
                const areTermsValid = planInfo?.coverages[0]?.term !== null && planInfo?.coverages[0]?.hours !== null;
                
                
                if (!isPlanValid || !isEquipmentNew || !isPromoCodeValid || !areTermsValid) {

                    this.failedVerifiSerialNumber = true;
                    return;
                }



                update && this.setQuoteData(this.quote)
                const baseWarranty = this.dataOfExtendTheExtend.plan_info.coverages
                    .filter(coverage => coverage.coverage_type == 'BASE' && 
                        coverage.term == this.dataOfExtendTheExtend.plan_info.mfr_term && 
                        coverage.hours == this.dataOfExtendTheExtend.plan_info.mfr_hours)[0];

                if(baseWarranty){
                    this.warrantyTermControl.patchValue(`${baseWarranty.term} / ${baseWarranty.hours} ${baseWarranty.coverage}`, { emitEvent: false });
                    this.warrantyTermControl.updateValueAndValidity();
                    this.warrantyTermControl.markAsDirty();
                }

                if (this.dataOfExtendTheExtend?.manufacturer) {
                    this.manufacturerControl.reset([], { emitEvent: true });
                    this.manufacturerControl.setValue(this.dataOfExtendTheExtend.manufacturer, { emitEvent: true });
                    this.manufacturerControl.markAsDirty();
                    this.manufacturerControl.updateValueAndValidity();
                }
                if (this.dataOfExtendTheExtend?.equipment_type) {
                    this.equipmentTypeControl.reset([], { emitEvent: false });
                    this.equipmentTypeControl.patchValue(this.dataOfExtendTheExtend.equipment_type, { emitEvent: true });
                    this.equipmentTypeControl.markAsDirty();
                }
                if (this.dataOfExtendTheExtend?.model) {
                    this.modelControl.reset([], { emitEvent: false });
                    this.modelControl.patchValue(this.dataOfExtendTheExtend.model, { emitEvent: true });
                    this.modelControl.markAsDirty();
                    
                }
                if (this.dataOfExtendTheExtend?.plan_info?.coverages?.length) {

                    this.existingCoverageTexts = ''
                    this.existingCoverageTexts = this.dataOfExtendTheExtend.plan_info.coverages[0].coverage_text;

                    if (this.dataOfExtendTheExtend?.plan_info?.coverages?.length >= 2) {
                        this.existingCoverageTexts = ''
                        this.existingCoverageTexts = this.dataOfExtendTheExtend?.plan_info?.coverages
                            .filter(coverage => coverage?.coverage_text && coverage.coverage_type === 'EW')
                            .map(coverage => coverage?.coverage_text)
                            .join(' & ');
                    }
                    
                    this.quoteForm.controls.existing_coverage.reset([], { emitEvent: false });
                    this.quoteForm.controls.existing_coverage.patchValue(this?.existingCoverageTexts, { emitEvent: true });
                    this.quoteForm.controls.existing_coverage.markAsDirty();
                    // this.existingCoverageControl

                }
                if (this.dataOfExtendTheExtend.plan_info.coverages[0].effective_date && this.dataOfExtendTheExtend.plan_info.coverages[0].expiration_date) {
                    const effectiveDate = new Date(this.dataOfExtendTheExtend.plan_info.coverages[0].effective_date);
                    const expirationDate = new Date(this.dataOfExtendTheExtend.plan_info.coverages[0].expiration_date);
                
                    const formattedEffectiveDate = effectiveDate.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                    });
                
                    const formattedExpirationDate = expirationDate.toLocaleDateString('en-US', {
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit'
                    });
                
                    this.coveragePeriodTexts = `${formattedEffectiveDate} - ${formattedExpirationDate}`;
                
                    this.coveragePeriodControl.reset([], { emitEvent: false });
                    this.coveragePeriodControl.patchValue(this.coveragePeriodTexts, { emitEvent: true });
                    this.coveragePeriodControl.markAsDirty();
                }
                if ( this.dataOfExtendTheExtend?.plan_info?.coverages[0]?.coverage_type ) {
                    this.coverageTypeControl.reset([], { emitEvent: false });
                    this.coverageTypeControl.patchValue(this.dataOfExtendTheExtend.plan_info.coverages[0].coverage_type, { emitEvent: true });
                    this.coverageTypeControl.markAsDirty();
                    
                }
                if ( this.dataOfExtendTheExtend?.plan_info?.coverages[0]?.service_fee != null ) {
                    this.serviceFeeOrDeductibleControl.reset([], { emitEvent: false });
                    this.serviceFeeOrDeductibleControl.patchValue(this.dataOfExtendTheExtend.plan_info.coverages[0].service_fee);
                    this.serviceFeeOrDeductibleControl.markAsDirty();
                }
                this.quoteForm.updateValueAndValidity()
            },
            ({error}) => {

                this.errorMessages.push(error.message);

            }
        );

    }
}